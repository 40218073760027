<script setup lang="ts">
import { SIDEBAR_IDS } from '@design-system/data/sidebarIds'

const { menuStatus } = useMenu()
const { currentWebsite } = useWebsite()
const { isDialogOpen: isDialogOpenMiniCart } = useDialog(
  SIDEBAR_IDS.headerSideCart
)
const { isDialogOpen: isDialogOpenLogin } = useDialog(SIDEBAR_IDS.loginSidebar)

const { data: items } = await useMarketingStrip(
  `${currentWebsite.value}-marketing-strips`
)
const messages = computed(() => items?.value!.content?.messages)
</script>

<template>
  <ClientOnly>
    <div class="theme-global min-h-screen">
      <HeaderStripWrapper
        :is-hidden="
          menuStatus?.isActive || isDialogOpenMiniCart || isDialogOpenLogin
        "
      >
        <MarketingStrip :messages="messages" />
      </HeaderStripWrapper>

      <HeaderWrapperLogic>
        <template #header="props">
          <HeaderDefault
            class="print:hidden"
            v-bind="{
              ...props,
              currentBrand: 'global',
              deferredCurrentBrand: 'global',
              staticOnMobile: true,
            }"
          />
        </template>
        <template #navigation="props">
          <HeaderNavigationBar v-bind="props" />
        </template>
      </HeaderWrapperLogic>

      <main id="main" class="bg-primitives-off-white h-full w-full md:mb-0">
        <slot />
      </main>

      <div class="mb-[var(--navbar-height)] lg:mb-0 print:hidden">
        <FooterMain />
      </div>
    </div>
    <GlobalSlides />
  </ClientOnly>
</template>
